import Vue from 'vue';
export default Vue.extend({
    name: 'App',
    methods: {
        showMessage() {
            this.$msg.show({
                message: this.message,
                color: this.color,
                position: this.position
            });
        }
    },
    data() {
        return {
            message: '',
            color: '',
            position: 0
        };
    },
    computed: {
        selectedPosition() {
            switch (this.position) {
                case 0: return 'TOP';
                case 1: return 'TOP_LEFT';
                case 2: return 'TOP_RIGHT';
                case 3: return 'BOTTOM';
                case 4: return 'BOTTOM_LEFT';
                case 5: return 'BOTTOM_RIGHT';
                default: return 'TOP';
            }
        }
    }
});
